import { onContentLoad } from "./onContentLoad";
import { addOverlay, removeOverlay } from "./utils";

export function initModals() {
	onContentLoad(function () {
		const modalButtons = document.querySelectorAll("[data-modal]");

		modalButtons.forEach((button) => {
			button.addEventListener("click", (e) => {
				e.preventDefault();
				initOpenedModal(button.dataset.modal);
				button.blur();
			});

			// Make button not focusable unless it is shown
			if (
				button.dataset.modal === "actionsMenu" &&
				window.innerWidth < 768
			) {
				button.setAttribute("tabindex", "0");
			}
		});
	});

	function initOpenedModal(id) {
		const modal = document.getElementById(id);
		const closeButtons = modal.querySelectorAll('[data-dismiss="modal"]');

		// Close other active modals
		const openedModals = document.querySelectorAll(
			'[aria-expanded="true"]',
		);
		openedModals.forEach((modal) => {
			closeModal(modal.getAttribute("id"));
		});

		openModal(id);

		closeButtons.forEach((close) => {
			close.addEventListener("click", (e) => {
				e.preventDefault();
				closeModal(id);
			});
		});

		if (modal.classList.contains("modal")) {
			modal.addEventListener("click", (e) => {
				if (!e.target.closest(".modal__container")) {
					closeModal(id);
				}
			});
		}

		document.addEventListener("keydown", (event) => {
			if (event.key === "Escape") {
				closeModal(id);
			}
		});
	}
}

export function openModal(id) {
	const modal = document.getElementById(id);

	if (!modal.classList.contains("open")) {
		modal.classList.add("open");
		modal.setAttribute("aria-expanded", "true");
		addOverlay();
		trapFocusInModal(modal);
	}
}

export function closeModal(id) {
	const modal = document.getElementById(id);

	if (modal.classList.contains("open")) {
		const errors = modal.querySelectorAll(".error");

		modal.classList.remove("open");
		modal.setAttribute("aria-expanded", "false");
		removeOverlay();
		releaseFocusInModal(modal);

		if (errors.length > 0) {
			errors.forEach((error) => {
				error.classList.remove("error");
			});
		}
	}
}

export function handleTabKeyPress(event, firstElement, lastElement) {
	if (event.key === "Tab") {
		const shiftKey = event.shiftKey;
		const activeElement = document.activeElement;

		// Trap focus inside modal
		if (event.key === "Tab") {
			if (shiftKey && activeElement === firstElement) {
				event.preventDefault();
				lastElement.focus();
			} else if (!shiftKey && activeElement === lastElement) {
				event.preventDefault();
				firstElement.focus();
			}
		}
	}
}

export function trapFocusInModal(modal) {
	const focusableElements = modal.querySelectorAll(
		'button:not([tabindex^="-"]), [href]:not([tabindex^="-"]), input:not([tabindex^="-"]), textarea:not([tabindex^="-"]), [tabindex]:not([tabindex^="-"])',
	);
	const firstElement = focusableElements[0];
	const lastElement = focusableElements[focusableElements.length - 1];

	// Focus first focusable element on open modal
	const modalHasFocusInside = modal.contains(document.activeElement);
	if (!modalHasFocusInside) {
		firstElement.focus();
	}

	modal.addEventListener("keydown", (event) =>
		handleTabKeyPress(event, firstElement, lastElement),
	);
}

export function releaseFocusInModal(modal) {
	modal.removeEventListener("keydown", handleTabKeyPress);
}
