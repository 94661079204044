import { accordion } from "./common/accordion";
import { ads } from "./common/ads";
import { cookies } from "./common/cookies";
import { forms } from "./common/forms";
import { globalSearch } from "./common/globalSearch";
import { header } from "./common/header";
import { marquee } from "./common/marquee";
import { notification } from "./common/notification";
import { print } from "./common/print";
import { tabs } from "./common/tabs";
import { toasts } from "./common/toasts/toasts";
import { toggleDesktopView } from "./common/toggleDesktopView";
import { industryTypeAndJobBanner } from "./industryTypeAndJobBanner";

export function initUI() {
	accordion();
	ads();
	cookies();
	forms();
	globalSearch();
	header();
	industryTypeAndJobBanner();
	marquee();
	notification();
	print();
	tabs();
	toasts();
	toggleDesktopView();
}
