import jquery from "jquery";
import { createSearchLink } from "../../common/hash/util";
import { getNewsDate, sanitizeHTML } from "../../search/utils";

export function globalSearch() {
	const container = document.getElementById("globalSearchSuggestions");
	const searchInput = document.getElementById("globalSearchInput");
	const searchButton = document.getElementById("global-search-icon-overlay");
	let request;
	let timeout;
	let query = "";
	let requestTime;

	if (searchInput) {
		searchInput.addEventListener("input", () => {
			query = searchInput.value;

			if (query.length > 1) {
				// If input has changed, abort previous request
				if (request != null) {
					request.abort();
					request = null;
				}

				// If search takes >10 seconds, abort it
				clearTimeout(requestTime);
				requestTime = setTimeout(() => request?.abort(), 10000);

				loading();
				clearTimeout(timeout);

				timeout = setTimeout(requestSearch, 2000);
			} else {
				// If input was cleared, abort request
				clearRequest();
			}
		});

		searchInput.addEventListener("keydown", (e) => {
			if (query?.length <= 1) {
				clearRequest();
				return;
			}

			if (e.code !== "Enter") {
				return;
			}

			if (request != null) {
				request.abort();
				request = null;
			}

			loading();
			clearTimeout(requestTime);
			requestTime = setTimeout(() => request?.abort(), 10000);
			clearTimeout(timeout);
			requestSearch();
		});

		searchButton.addEventListener("click", () => {
			if (query.length <= 1) {
				clearRequest();
				return;
			}

			if (request != null) {
				request.abort();
				request = null;
			}

			loading();
			clearTimeout(requestTime);
			requestTime = setTimeout(() => request?.abort(), 10000);
			clearTimeout(timeout);
			requestSearch();
		});
	}

	function requestSearch() {
		if (query.length <= 1) {
			hideLoading();
			return;
		}

		request = jquery
			.ajax({
				type: "GET",
				url: `/api/v1/search?query=${query}`,
				dataType: "json",
			})
			.done((data) => {
				if (data.length > 0) {
					showResults(data);
				} else {
					noResults(container);
				}
				clearTimeout(requestTime);
			})
			.fail((error) => {
				clearTimeout(requestTime);
				if (error.responseJSON?.error?.code === "query_too_short") {
					hideLoading();
				} else {
					const title =
						error.statusText === "abort"
							? "The search is taking too long to respond"
							: error.statusText;
					noResults(container, title, "Please try again later.");
				}
			});
	}

	function clearRequest() {
		if (request != null) {
			request.abort();
			clearTimeout(requestTime);
			clearTimeout(timeout);
			request = null;
		}
	}

	function showResults(data) {
		const news = [];
		const sidebar = [];
		data.forEach((item) =>
			item.type === "manual_news"
				? news.push(item.data)
				: sidebar.push(item),
		);
		hideLoading();
		showNewsItems(news);
		showSidebarItems(sidebar, query);
		news.length > 0 && showViewAllButton(query);
	}

	function showNewsItems(news) {
		const newsContainer = document.createElement("div");
		newsContainer.classList.add("news-list", "grid__col-auto");
		container.appendChild(newsContainer);

		if (news.length === 0) {
			noResults(newsContainer);
		} else {
			news.map((item) =>
				container.firstChild.appendChild(createNewsItem(item)),
			);
		}
	}

	function showSidebarItems(items) {
		if (items.length === 0) {
			return null;
		}

		const sidebar = document.createElement("aside");
		sidebar.classList.add("sidebar");
		const title = document.createElement("p");
		title.classList.add("sidebar-title");
		title.innerText = "You may be interested in:";
		const suggestions = document.createElement("ul");
		suggestions.classList.add("sidebar-suggestions");

		sidebar.appendChild(title);
		sidebar.appendChild(suggestions);
		items.map((item) => suggestions.appendChild(createSidebarItem(item)));
		container.appendChild(sidebar);
	}

	function showViewAllButton(query) {
		container.appendChild(createViewAllButton(query));
	}

	function loading() {
		if (!container.classList.contains("loading")) {
			container.classList.add("loading");
			container.innerHTML = `<span class="loader" id="globalSearchLoader">Loading...</span>`;
		}
	}

	function hideLoading() {
		if (container.classList.contains("loading")) {
			container.classList.remove("loading");
			container.innerHTML = "";
		}
	}

	function noResults(
		container,
		title = "No results",
		content = "There are no results at the moment.",
	) {
		hideLoading();
		container.innerHTML = `<div class="no-search-results-wrapper"><div class="no-search-results grid">
                                   <img src="/static/img/icons/icon-no-search-results.svg" alt="No results">
                                   <h5>${title}</h5><p>${content}</p>
                               </div></div>`;
	}

	const createSidebarItem = (item) => {
		const listItem = document.createElement("li");
		const link = document.createElement("a");
		link.href = item.data.url;
		link.innerText = item.data.label;

		if (item.data.additional) {
			const linkAdditional = document.createElement("p");
			linkAdditional.innerText = item.data.additional;
			link.appendChild(linkAdditional);
		}

		listItem.appendChild(link);

		return listItem;
	};

	const createNewsItem = (item) => {
		const title = item.title;
		const text = item.render_result;
		const date = getNewsDate(item.published_at);
		const url = item.url;
		const type = item.type;

		// Breadcrumbs
		const newsSubcategory = document.createElement("li");
		const link = document.createElement("a");

		if (type === "routes") {
			link.href = createNewsSearchUrl("typeRoute");
			link.innerText = "Route News";
		} else if (type === "particulars") {
			link.href = createNewsSearchUrl("typePeople");
			link.innerText = "Management News";
		} else {
			link.href = createNewsSearchUrl("typeGeneral");
			link.innerText = "Latest News";
		}

		newsSubcategory.appendChild(link);

		// Tags
		const pro = item.is_pro;
		const exclusive = item.is_exclusive;
		const curated = type === "curated";

		const tagPro = document.createElement("li");
		tagPro.classList.add("tag", "pro");
		tagPro.innerText = "Pro";

		const tagExclusive = document.createElement("li");
		tagExclusive.classList.add("tag");
		tagExclusive.innerText = "Exclusive";

		const tagCurated = document.createElement("li");
		tagExclusive.classList.add("tag", "other-publisher");
		tagExclusive.innerText = "News by other publishers";

		let tagsList;

		if (pro || exclusive || curated) {
			const tagsList = document.createElement("ul");
			tagsList.classList.add("post__tags");

			if (pro) {
				tagsList.appendChild(tagPro);
			}

			if (exclusive) {
				tagsList.appendChild(tagPro);
				tagsList.appendChild(tagExclusive);
			}

			if (curated) {
				tagsList.appendChild(tagCurated);
			}
		}

		const news = document.createElement("article");
		news.classList.add("post", "post-without-images");

		const postBreadcrumbs = document.createElement("div");
		postBreadcrumbs.classList.add("post__breadcrumbs");
		const postBreadcrumbsList = document.createElement("ul");
		postBreadcrumbsList.classList.add("breadcrumbs", "breadcrumbs-sm");
		const postBreadcrumbsItem = document.createElement("li");
		const postBreadcrumbsLink = document.createElement("a");
		postBreadcrumbsLink.innerText = "News";
		postBreadcrumbsLink.href = "/news";
		postBreadcrumbsItem.appendChild(postBreadcrumbsLink);
		postBreadcrumbsList.appendChild(postBreadcrumbsItem);
		postBreadcrumbsList.appendChild(newsSubcategory);
		postBreadcrumbs.appendChild(postBreadcrumbsList);

		const postHeader = document.createElement("div");
		postHeader.classList.add("post__header");
		const postHeaderTitle = document.createElement("h2");
		postHeaderTitle.classList.add("post__title");
		const postHeaderLink = document.createElement("a");
		postHeaderLink.innerText = title;
		postHeaderLink.href = url;
		postHeaderTitle.appendChild(postHeaderLink);

		if (tagsList) {
			postHeader.appendChild(tagsList);
		}

		postHeader.appendChild(postHeaderTitle);

		const postLead = document.createElement("div");
		postLead.classList.add("post__lead");
		const postLeadText = document.createElement("p");
		postLeadText.innerHTML = sanitizeHTML(text);
		postLead.appendChild(postLeadText);

		const postFooter = document.createElement("footer");
		postFooter.classList.add("post__footer");
		const postFooterDate = document.createElement("p");
		postFooterDate.classList.add("post__date");
		postFooterDate.innerText = date;
		const postFooterUrl = document.createElement("a");
		postFooterUrl.classList.add("post__url");
		postFooterUrl.href = url;
		postFooterUrl.innerText = url;
		postFooter.appendChild(postFooterDate);
		postFooter.appendChild(postFooterUrl);

		const postContent = document.createElement("div");
		postContent.classList.add("post__content");
		postContent.appendChild(postBreadcrumbs);
		postContent.appendChild(postHeader);
		postContent.appendChild(postLead);
		postContent.appendChild(postFooter);

		news.appendChild(postContent);

		return news;
	};

	const createViewAllButton = (query) => {
		const button = document.createElement("div");
		button.classList.add("view-all", "grid__col-12");

		const link = document.createElement("a");
		link.appendChild(document.createTextNode("View all results"));
		link.href = `/search?query=${encodeURIComponent(query)}`;
		link.classList.add("btn", "btn--secondary", "btn-full-width");
		button.appendChild(link);

		return button;
	};

	const createNewsSearchUrl = (newsType) =>
		createSearchLink("/news", { news_type: [newsType] });
}
